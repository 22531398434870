import React from "react"
import PropTypes from "prop-types"

import cssStyle from "./style.module.css"

const Container = ({ children }) => (
  <div className={cssStyle.container}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
