import React from "react"

import lessStyle from "./style.module.less"

const SplitBackground = () => (
  <div className={lessStyle.splitBackground}>
    <div className={lessStyle.left}></div>
    <div className={lessStyle.center}></div>
    <div className={lessStyle.right}></div>
  </div>
)

export default SplitBackground
