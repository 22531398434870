import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"

const Helmet = () => (
  <ReactHelmet>
    <meta charSet="utf-8" />
    <link rel="icon" href="favicon.ico" />
    <title>Billy Ros - Développeur Web - Freelance</title>
    <meta
      name="description"
      content="Développeur freelance passionné spécialisé dans le développement web, je propose mes services pour la création de sites web ou des développement spécifique. J'aide les entreprises et les particuliers à développer leur système d'information en participant au développement de leurs projets Web."
    />
    <meta
      name="viewport"
      content="width=device-width , height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Bahianita&display=swap"
      rel="stylesheet"
    />
  </ReactHelmet>
)

export default Helmet
