import React, { useState, useEffect } from "react"

import lessStyle from "./style.module.less"

function getScrollPercentage() {
  return (
    (document.body.scrollTop * 100) /
    (document.body.scrollHeight - document.body.clientHeight)
  )
}

const HorizontalScrollIndicator = () => {
  const [indicatorWidth, setIndicatorWidth] = useState(0)

  const handleScroll = () => {
    setIndicatorWidth((getScrollPercentage() * document.body.clientWidth) / 100)
  }

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll)
    return () => {
      document.body.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={lessStyle.horizontalScrollIndicator}
      style={{
        width: `${indicatorWidth}px`,
      }}
    ></div>
  )
}

export default HorizontalScrollIndicator
